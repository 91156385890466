import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import paymentMethods from '../../../assets/images/payment-methods.png';
import logoFooter from '../../../assets/images/logo-footer.svg';
import { EMOJI, RELOAD_DOCUMENT_REQUIRED, STATUS } from '../../../shared/enums';
import { selectMailchimpData, selectMailchimpError, selectMailchimpStatus } from '../../features/mailchimp/mailchimpSlice';

const LazyImg = loadable(() => import('../common/LazyImg'));
const MailchimpSubscribeForm = loadable(() => import('./MailchimpSubscribeForm'));

/**
 * @param pathname
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({ pathname }) => {
  const mailchimp = useSelector(selectMailchimpData);
  const error = useSelector(selectMailchimpError);
  const status = useSelector(selectMailchimpStatus);

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__primary'>
          <div className='footer__primary-inner'>
            <div className='footer__logo'>
              <a href='/'>
                <span className='screen-reader-text'>Mila branding</span>
                <LazyImg src={logoFooter} alt='Mila' />
              </a>
            </div>
            <nav className='footer__nav'>
              <ul className='footer__nav-list'>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/filters'>Filters</a>
                      : <Link to='/filters'>Filters</Link>
                  }
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/our-story'>Our Story</a>
                      : <Link to='/our-story'>Our Story</Link>
                  }
                </li>
                <li>
                  <a
                    target='_blank'
                    href='https://docs.milacares.com/legal/warranty.pdf'
                    rel='noreferrer'
                  >
                    Warranty
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    href='https://docs.milacares.com/legal/terms.pdf'
                    rel='noreferrer'
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href='/buy/filters'>Buy Filters</a>
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/careers'>Careers</a>
                      : <Link to='/careers'>Careers</Link>
                  }
                </li>
              </ul>
              <ul className='footer__nav-list'>
                <li>
                  <a
                    target='_blank'
                    href='https://docs.milacares.com/legal/privacy.pdf'
                    rel='noreferrer'
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/performance'>Performance</a>
                      : <Link to='/performance'>Performance</Link>
                  }
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/why-mila'>Why Mila?</a>
                      : <Link to='/why-mila'>Why Mila?</Link>
                  }
                </li>
                <li>
                  <a href={MILA_MY_ACCOUNT_HOST}>My Mila Account</a>
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/support'>Support</a>
                      : <Link to='/support'>Support</Link>
                  }
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/app/'>Mila App</a>
                      : <Link to='/app/'>Mila App</Link>
                  }
                </li>
                <li>
                  {
                    RELOAD_DOCUMENT_REQUIRED.includes(pathname)
                      ? <a href='/accessibility-policy/'>Accessibility Policy</a>
                      : <Link to='/accessibility-policy/'>Accessibility Policy</Link>
                  }
                </li>
              </ul>
            </nav>
          </div>
          <div className='subscribe-block'>
            <h3 className='subscribe-block__title'>Let’s connect!</h3>
            <ul className='social-link'>
              <li>
                <a href='https://facebook.com/milacaresquad' target='_blank' rel='noreferrer'>
                  <span className='screen-reader-text'>facebook</span>
                  <svg className='icon icon-facebook'>
                    <use xlinkHref='#icon-facebook' />
                  </svg>
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/milacaresquad/' target='_blank' rel='noreferrer'>
                  <span className='screen-reader-text'>instagram</span>
                  <svg className='icon icon-instagram'>
                    <use xlinkHref='#icon-instagram' />
                  </svg>
                </a>
              </li>
            </ul>
            <p
              className='subscribe-block__description'
            >
              Don’t miss the latest on Mila news and offers.
              {EMOJI.ONCOMING_FIST}
            </p>
            <div id='mc_embed_signup' className='subscribe-block__form'>
              <MailchimpSubscribeForm />
            </div>
            {((mailchimp || error) && status !== STATUS.LOADING) && (
              <p className='subscribe-block__form-message'>
                {mailchimp && mailchimp.title}
                {error && error.error}
              </p>
            )}
          </div>
        </div>
        <div className='footer__secondary'>
          <p className='copyright'>&copy; 2023 All Rights Reserved</p>
          <LazyImg className='payment-image' src={paymentMethods} alt='payment methods' />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
